// import PropTypes from 'prop-types';
import s from './footer.module.css';
// components/Footer.js

const footer = () => {
  return (
    <footer className="bg-neutralLight p-6 border-t border-neutralDark">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
        <div>
          <p className="text-neutralDark">© 2024 My Educational Platform</p>
        </div>
        <div>
          <a href="/privacy" className="text-secondary hover:text-primary">
            Privacy Policy
          </a>
        </div>
        <div>
          <a href="/terms" className="text-secondary hover:text-primary">
            Terms of Service
          </a>
        </div>
      </div>
    </footer>
  );
};

footer.propTypes = {};

footer.defaultProps = {};

export default footer;
