import dynamic from 'next/dynamic';

export default {
    AboutPage: dynamic(() => import('./AboutPage')),
    ArticlePage: dynamic(() => import('./ArticlePage')),
    AuthorPage: dynamic(() => import('./AuthorPage')),
    AuthorsPage: dynamic(() => import('./AuthorsPage')),
    BasePage: dynamic(() => import('./BasePage')),
    CardsPage: dynamic(() => import('./CardsPage')),
    GamePage: dynamic(() => import('./GamePage')),
    HomePage: dynamic(() => import('./HomePage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
};
