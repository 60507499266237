//components/Navbar.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Navbar.module.css'; // Import the CSS module
import LanguageSwitcher from '../LanguageSwitcher';

let hasNavbarRendered = false;

const Navbar = ({ primaryPages = [], locales = [] }) => {
    const [activePage, setActivePage] = useState(null);

    // Simply render the component without conditional logic
    return (
        <nav className={`${styles.Root} bg-neutralLight border-b border-neutralLight`}>
            <a href="/" className={`${styles.NavLink} text-primary`}>My Brand</a>
            <ul className={styles.Navbar}>
                {primaryPages.map((page) => (
                    <li
                        key={page.id}
                        className={`${styles.NavItem} ${activePage === page.id ? styles.NavItemActive : ''}`}
                        onClick={() => setActivePage(page.id)} // Set the active page on click
                    >
                        <a href={page.url} className={`${styles.NavLink} ${activePage === page.id ? 'text-white' : 'text-secondary'}`}>
                            {page.title}
                        </a>

                        {/* Secondary pages dropdown */}
                        {page.secondaryPages && page.secondaryPages.length > 0 && (
                            <ul className={styles.Dropdown}>
                                {page.secondaryPages.map((subPage) => (
                                    <li key={subPage.id} className={styles.DropdownItem}>
                                        <a href={subPage.url} className="text-accent">
                                            {subPage.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
                {/* Pass locales to LanguageSwitcher */}
                <li className={styles.LanguageSwitcher}>
                    <LanguageSwitcher locales={locales} />
                </li>
            </ul>
        </nav>
    );
};

Navbar.propTypes = {
    primaryPages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        secondaryPages: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })),
    })),
};

export default Navbar;
