import React from 'react';
import PropTypes from 'prop-types';
import s from './LanguageSwitcher.module.css'; // Assuming you have a CSS module for styling
import { useRouter } from 'next/router';

const LanguageSwitcher = ({ locales = [] }) => {
    const router = useRouter();  // Use Next.js router to get the current route
    const currentPath = router.asPath;  // Get the current path (e.g., /en/my-authors)

    // Function to construct a URL with the selected language
    const getLocalizedPath = (localeCode) => {
        // Strip the existing language code from the current path
        const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}/, '');
        // Return the new path with the selected language code prepended
        return `/${localeCode}${pathWithoutLang}`;
    };

    return (
        <div className={s.Root}>
            {/* Language toggle */}
            <ul className={s.LanguageList}>
                {locales.map((locale) => (
                    <li key={locale.languageCode} className={s.LanguageItem}>
                        {/* Use getLocalizedPath to create the correct URL */}
                        <a href={getLocalizedPath(locale.languageCode)} className={s.LanguageLink}>
                            {locale.languageName}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

LanguageSwitcher.propTypes = {
    locales: PropTypes.arrayOf(
        PropTypes.shape({
            languageCode: PropTypes.string.isRequired,
            languageName: PropTypes.string.isRequired,
        })
    ),
};

export default LanguageSwitcher;
