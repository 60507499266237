// components/Layout.js
import React from 'react';
import PropTypes from 'prop-types';
import s from './Layout.module.css';
import Header from '../header';
import Footer from '../footer';
import PageContent from '../PageContent';

const Layout = ({ title, intro, authorImage, children, primaryPages, locales }) => {
  return (
    <div className={`${s.Root} flex flex-col min-h-screen`}>
      {/* Pass primaryPages and locales to Header */}
      <Header primaryPages={primaryPages} locales={locales} />
      <main className="flex-grow">
        <PageContent title={title} intro={intro} authorImage={authorImage}>
          {children}
        </PageContent>
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  authorImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  children: PropTypes.node,
  primaryPages: PropTypes.arrayOf(PropTypes.object),
  locales: PropTypes.arrayOf(PropTypes.object),
};

Layout.defaultProps = {
  title: '',
  intro: '',
  authorImage: null,
  primaryPages: [],
  locales: [],
};

export default Layout;

