// components/Header.js
import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '../Navbar';  // Import the custom Navbar component

const Header = ({ primaryPages = [], locales = [] }) => {  // Add locales here
    if (primaryPages.length === 0) {
        return (
            <header>
                <Navbar locales={locales} />  {/* Pass locales here */}
            </header>
        );
    }

    return (
        <header>
            <Navbar primaryPages={primaryPages} locales={locales} />  {/* Pass locales here */}
        </header>
    );
};

Header.propTypes = {
    primaryPages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    })),
};

export default Header;
