// ./pages/[...path].js
import querystring from 'querystring';
import { getPage, getRedirect, getPrimaryPages, getSecondaryPages, getLocales, WagtailApiResponseError } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';
import Header from '../components/header';
import Layout from '../components/Layout';

const isProd = process.env.NODE_ENV === 'production';

export default function CatchAllPage({ componentName, componentProps, primaryPages, locales }) {
    const Component = LazyContainers[componentName];
    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }
    return (
        <Layout primaryPages={primaryPages} locales={locales}>
            <Component {...componentProps} />
        </Layout>
    );
}

// For SSR
// For SSR
export async function getServerSideProps({ req, params, res }) {
    let path = params?.path || [];
    path = path.join('/');

    const { host } = req.headers;
    let queryParams = new URL(req.url, `https://${host}`).search;
    if (queryParams.indexOf('?') === 0) {
        queryParams = queryParams.substr(1);
    }
    queryParams = querystring.parse(queryParams);

    // Initialize arrays for primary pages and locales
    let primaryPages = [];
    let locales = [];

    // Fetch primary pages first
    try {
        //console.log('Fetching primary pages...');
        primaryPages = await getPrimaryPages();
        //console.log('Fetched primary pages:');

        // Fetch secondary pages for each primary page
        for (const primaryPage of primaryPages) {
            if (primaryPage.url) {
                try {
                    const secondaryPages = await getSecondaryPages(primaryPage.url);
                    primaryPage.secondaryPages = secondaryPages;
                } catch (err) {
                    console.error(`Error fetching secondary pages for ${primaryPage.url}:`, err);
                }
            }
        }
    } catch (err) {
        console.error('Error fetching primary pages:', err);
    }

    // Fetch locales after primary pages are fetched
    try {
        //console.log('Fetching locales...');
        locales = await getLocales();  // Fetch locales
        //console.log('Fetched locales:', locales);  // Log the fetched locales
    } catch (err) {
        console.error('Error fetching locales:', err);
    }

    // Try to serve the page
    try {
        const {
            json: { componentName, componentProps, redirect, customResponse },
            headers,
        } = await getPage(path, queryParams, {
            headers: {
                cookie: req.headers.cookie,
                host,
            },
        });

        // Forward any cookie we encounter
        const cookies = headers.get('set-cookie');
        if (cookies) {
            res.setHeader('Set-Cookie', cookies);
        }

        // Serve custom response if available
        if (customResponse) {
            const { body, body64, contentType } = customResponse;
            res.setHeader('Content-Type', contentType);
            res.statusCode = 200;
            res.write(body64 ? Buffer.from(body64, 'base64') : body);
            res.end();
            return { props: {} };
        }

        // Handle redirection if necessary
        if (redirect) {
            const { destination, isPermanent } = redirect;
            return {
                redirect: {
                    destination: destination,
                    permanent: isPermanent,
                },
            };
        }

        // Pass both primaryPages and locales as props
        return { props: { componentName, componentProps, primaryPages, locales } };
    } catch (err) {
        if (!(err instanceof WagtailApiResponseError)) {
            console.error('Error fetching page:', err);
            throw err;
        }
        return { props: {} };
    }
}


// For SSG
/*
export async function getStaticProps({ params, preview, previewData }) {
    params = params || {};
    let path = params.path || [];
    path = path.join("/");

    const { json: pageData } = await getPage(path);
    return { props: pageData }
}

export async function getStaticPaths() {
    const { json: data } = await getAllPages();

    let htmlUrls = data.items.map(x => x.relativeUrl);
    htmlUrls = htmlUrls.filter(x => x);
    htmlUrls = htmlUrls.map(x => x.split("/"));
    htmlUrls = htmlUrls.map(x => x.filter(y => y))
    htmlUrls = htmlUrls.filter(x => x.length)

    const paths = htmlUrls.map(x => (
        { params: { path: x } }
    ));

    return {
        paths: paths,
        fallback: false,
    };
}
*/
